import React from 'react'
import { ContactIcon, Footer, SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'
import '../../styles/screen.styles/contact.scss'
import { redirect } from '../../utils'
import { ContactCard } from './contact.card'

export function Contact() {
    return (
        <section id="contact" className="contact-module">
            <div className="contact-wrapper">
                <SlideUpAnimation slideFrom={20}>
                    <title className="title">
                        <ContactIcon className="section-header-icon" />
                        {CONTENT.contact.title}
                    </title>
                </SlideUpAnimation>
                <SlideUpAnimation delay="0.5s" slideFrom={50}>
                    <ContactCard />
                </SlideUpAnimation>
                <SlideUpAnimation delay="2s" slideFrom={20}>
                    <div
                        onClick={() => redirect(CONTENT.links.linkedIn)}
                        className="contact-action-button app-button"
                    >
                        <span className="hover-underline-animation">
                            {CONTENT.contact.linkText}
                        </span>
                    </div>
                </SlideUpAnimation>
            </div>
            <Footer />
        </section>
    )
}
