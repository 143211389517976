import React from 'react'
import { SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'
import { redirect } from '../../utils'
export function WelcomeMessage() {
    return (
        <div className="welcome-measage">
            <SlideUpAnimation slideFrom={20}>
                <div className="header">{CONTENT.home.wish}</div>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.3s" slideFrom={20}>
                <div className="title-1">
                    <span className="hover-underline-animation">{CONTENT.home.name}</span>.
                </div>
            </SlideUpAnimation>
            <SlideUpAnimation delay="1.2s" slideFrom={20}>
                <div className="title-2">{CONTENT.home.description1}</div>
            </SlideUpAnimation>
            <SlideUpAnimation delay="1.5s" slideFrom={20}>
                <div className="content">{CONTENT.home.description2}</div>
            </SlideUpAnimation>
            <SlideUpAnimation delay="2s" slideFrom={20}>
                <div onClick={() => redirect(CONTENT.links.linkedIn)} className="home-action-button app-button">
                    <span className="hover-underline-animation">
                        {CONTENT.home.directLinkText}
                    </span>
                </div>
            </SlideUpAnimation>
        </div>
    )
}
