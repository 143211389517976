export const CONTENT = {
    home: {
        wish: `Hi There, I’m`,
        name: `Lijo Vijayan`,
        description1: `I love to bring happiness with technology.`,
        description2: `I’m a software developer specializing in development of web and mobile applications with exceptional digital experiences.`,
        directLinkText: `Get In Touch`
    },
    about: {
        title: `About Me`,
        description: [
            `Hello, Myself Lijo. I’m a self-taught developer focusing on developing software that revolutionizes the cosmos. 
            My primary area of interest is web application development; besides, I love to create interactive hybrid mobile applications.
            `,
            `Programming has always been my passion and hobby for a long and this helped me to stay up-to-date from year to year 
            and I’m still learning every single day to ensure I can deliver the most modern and effective solutions. 
            I believe in working hard and smart instead of searching for “hacks and shortcuts”. During the last few years, 
            I had the opportunity to work with amazing and ambitious developers 
                and we develop software that bring happiness to the users.`,
            `Here are a few technologies I’ve been working with recently:`
        ],
        frameworks: [
            {
                name: `React.Js`,
                rating: 8.5
            },
            {
                name: `Angular`,
                rating: 8.5
            },
            {
                name: `Node.Js`,
                rating: 8.5
            },
            {
                name: `React Native`,
                rating: 8.5
            },
            {
                name: `Flutter`,
                rating: 8.5
            },
            {
                name: `Tailwind CSS`,
                rating: 7
            }
        ]
    },
    experience: {
        title: `Where I’ve Worked`,
        firms: [
            {
                shortName: `Gather AI`,
                longName: `Gather AI`,
                designation: `Software Developer`,
                url: `https://gather.ai/`,
                from: `February 2021`,
                to: `Present`,
                experiences: [
                    `The world's first software-only autonomous inventory management platform for modern warehouses.`,
                    `Gather AI uses a proprietary autonomy engine featuring the state of the art in robotics, computer vision and deep learning.`
                ]
            },
            {
                shortName: `Blue Ripples`,
                longName: `Blue Ripples Technologies`,
                designation: `Software Developer`,
                url: `https://www.blueripples.com`,
                from: `July 2019`,
                to: `February 2021`,
                experiences: [
                    `Collaborated with project managers and creative teams to develop, 
                    re-architect and deliver performance optimized and 
                    user-friendly single page web applications.`,
                    `Provided prompt solutions for complex problems as a software 
                    developer of the development team.`,
                    `Followed Design patterns to enhance code reuse and accommodate change 
                    by supplying well-tested mechanisms for delegation and composition, 
                    as well as component-based reuse techniques.`
                ]
            }
        ]
    },
    projects: {
        title: `Projects`,
        featured: [
            {
                name: `Channel Logix`,
                description: `Channel logix is an enterprise resource planning platform for sales professionals. 
                    Its a cloud based application to manage various workflows that allow manufacturing 
                    and distribution businesses the ability to gain greater visibility into all operations 
                    while increasing speed, efficency, and overall customer satisfication.`,
                technologies: [`React`, `Redux`, `Antd`],
                imageURL: `${process.env.PUBLIC_URL}/images/1.jpg`
            },
            {
                name: `Client Details Protected by NDA`,
                description: `An analytics and reporting platform for trading and banking services 
                    which provides detailed performance and business level analysis of trading 
                    flows and market data for ultimate visibility.`,
                technologies: [
                    `Angular`,
                    `Node.js`,
                    `Sequelize`,
                    `Maria DB`,
                    `SQLite`
                ],
                imageURL: `${process.env.PUBLIC_URL}/images/2.jpg`
            },
            {
                name: `Personal Portfolio`,
                description: `A minimal and elegant portfolio which is adaptive to various devices and browsers.`,
                technologies: [`React`, `Tailwind CSS`, `Jest`, `Particles.Js`],
                imageURL: `${process.env.PUBLIC_URL}/images/3.jpg`
            }
        ],
        noteworthyProjects: `Other Noteworthy Projects`,
        other: [
            {
                name: `Quote of the day`,
                description: `Words that are well crafted can leave a lasting impact on the world. 
                A good quote helps to tell a story and enhance the credibility of a press release, news story, or speech. 
                This web page will generate inspiring quotes every day from 5000+ quotes by famous people around the world.`,
                technologies: [`Flutter`, `Delta player`, `Provider`],
                gitURL: `https://quotes.lijovijayan.dev`
            },
            {
                name: `Slate`,
                description: `E-learning platform UI developed with Flutter framework. It’s an application 
                    for schools and teaching professionals to share educational content and 
                    evaluate students through a virtual classroom environment.`,
                technologies: [`Flutter`, `Delta player`, `Provider`],
                gitURL: `https://github.com/lijovijayan/slate`
            },
            {
                name: `Darkhold`,
                description: `Simple and outstanding app to organize day-to-day tasks with a very easy-to-use interface. 
                Users can create, group, update and remove tasks based on needs. implemented smooth animations on controls 
                that provide the user an interactive experience.`,
                technologies: [`Flutter`, `SQLite`, `Provider`],
                gitURL: `https://github.com/lijovijayan/darkhold`,
                demoURL: `https://itsallwidgets.com/darkhold`
            },
            {
                name: `Delta player`,
                description: `A video player plugin for Flutter framework with custom animation controls. 
                    This plugin enhances the video player interactivity 
                    and visual representation of video player controls with smooth animations. 
                    Delta player plugin will support on both android and iOS devices.`,
                technologies: [`Flutter`],
                gitURL: `https://github.com/lijovijayan/delta_player`
            },
            {
                name: `VDV Digital`,
                description: `An interactive hybrid mobile application for cable tv users to manage packages 
                and make payments over the application, integrated payment gateway with webview 
                for seamless transactions. Users can report and track concerns through complaints panel, 
                minimal and elegant UI provides a smooth experience for the users.`,
                technologies: [`Flutter`, `Webview`, `Dio`, `Provider`]
            },
            {
                name: `Vote X`,
                description: `Multi-purpose online voting system powered by blockchain-inspired technology to secure polling records. 
                    Users can create polls and manage voters and candidates list with interactive UI, voters will have 
                    unique identification besides that, polling can be done virtually over the Web page - done as an academic project.`,
                technologies: [`Vannila Js`, `PHP`, `MySQL`, `Bootstrap`]
            },
            {
                name: `Providence`,
                description: `A Hospital management software to manage patient records and to deal with day-to-day 
                operations and management of the hospital activities - done as an academic project.`,
                technologies: [`Java`, `MySQL`, `Netbeans`]
            },
            {
                name: `Localization Demo App`,
                description: `Simple demo application developed with flutter that supports I18N support from server content. 
                    localization records will be fetched from a remote server and locally cache for performance improvements.`,
                technologies: [`Flutter`, `Provider`],
                gitURL: `https://github.com/lijovijayan/localization_demo`
            },
            {
                name: `Tic-Tac-Toe`,
                description: `An interactive Tic-Tac-toe(noughts and crosses, or Xs and Os) game developed with Angular framework. 
                This classic game contributes to brain development in numerous ways including the understanding of predictability, 
                problem-solving, spatial reasoning, and turn-taking.`,
                technologies: [`Flutter`],
                gitURL: `https://github.com/lijovijayan/tic-tac-toe`,
                demoURL: `https://lijovijayan.github.io/tic-tac-toe`
            },
            {
                name: `Content Listing App Demo`,
                description: `Responsive content listing app demo with React and Redux for beginners to learn Redux, 
                Redux-thunk, and lazy loading of contents to improve application performance.`,
                technologies: [`React`, `Redux`, `Tailwind CSS`],
                gitURL: `https://github.com/lijovijayan/react-content-listing`,
                demoURL: `https://lijovijayan.github.io/react-content-listing`
            },
            {
                name: `Collatz conjucture visualizer`,
                description: `Collatz conjucture series visualizer with Charts.Js`,
                technologies: [`React`, `Redux`, `Tailwind CSS`],
                gitURL: `https://github.com/lijovijayan/collatz-conjecture`,
                demoURL: `https://lijovijayan.github.io/collatz-conjecture`
            },
            {
                name: `Instagram Clone UI`,
                description: `Instagram clone UI developed with React-Native.`,
                technologies: [`React-Native`],
                gitURL: `https://github.com/lijovijayan/social_media_app`
            }
        ],
        showMore: `Show More`,
        showLess: `Show Less`
    },
    contact: {
        title: `Get In Touch`,
        description: `Although I’m not currently looking for any new opportunities, my inbox is always open. 
        Whether you have a question or just want to say hi, I’ll try my best to get back to you!`,
        linkText: `Say Hello!`
    },

    links: {
        github: `https://github.com/lijovijayan`,
        linkedIn: `https://www.linkedin.com/in/lijovijayan/`,
        email: `lijovijayan00@gmail.com`,
        twitter: `https://twitter.com/lijovijayan00`,
        instagram: `https://instagram.com/lijovijayan`,
        facebook: ``
    }
}
