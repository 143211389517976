import React, { useMemo } from 'react'
import {
    Align,
    FeaturedProjectCard,
    IFeaturedProject,
    ProjectsIcon,
    SlideUpAnimation
} from '../../components'
import { CONTENT } from '../../config/content'

export function FeaturedProjects() {
    const projects = useMemo<IFeaturedProject[]>(() => {
        const _projects: IFeaturedProject[] = CONTENT.projects.featured
        return _projects
    }, [])

    return (
        <div className="featured-projects">
        <SlideUpAnimation slideFrom={20}>
            <div className="project-screen-title">
                <ProjectsIcon className="section-header-icon" />
                {CONTENT.projects.title}
            </div>
        </SlideUpAnimation>
            {projects.map((project, i) => (
                <SlideUpAnimation
                    key={`proj-featured-item-${i}`}
                    slideFrom={70}
                    delay="0.3s"
                >
                    <FeaturedProjectCard
                        {...project}
                        direction={i % 2 === 0 ? Align.RIGHT : Align.LEFT}
                    />
                </SlideUpAnimation>
            ))}
        </div>
    )
}
