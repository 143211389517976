import React from 'react'
import { FaGithub } from 'react-icons/fa'
import '../styles/component.styles/featured.project.card.scss'
import { redirect } from '../utils'
export enum Align {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}
export interface IProject {
    name: string
    description: string
    technologies: string[]
    gitURL?: string
    demoURL?: string
}
export interface IFeaturedProject extends IProject {
    imageURL: string
}
interface Props extends IFeaturedProject {
    direction: Align
}

export function FeaturedProjectCard({
    name,
    description,
    technologies,
    imageURL,
    gitURL,
    direction
}: Props) {
    return (
        <div
            className={`featured-project-card-wrapper ${
                direction === Align.LEFT ? 'featured-align-left' : ''
            }`}
        >
            <div className="featured-thumb">
                <img draggable={false} src={imageURL} alt="" />
                <div className="thumb-filter"></div>
            </div>
            <div className="featured-content">
                <header className="featured-title">Featured Project</header>
                <title className="featured-header">
                    {name}
                </title>
                <div className="featured-description">{description}</div>
                <ol className="featured-technologies">
                    {technologies.map((tech, i) => (
                        <li
                            key={`comp-featured-proj-card-item-${i}`}
                            className="featured-technology"
                        >
                            {tech}
                        </li>
                    ))}
                </ol>
                <footer className="featured-footer">
                    {gitURL && (
                        <FaGithub
                            className="featured-git-icon"
                            onClick={() => redirect(gitURL)}
                        />
                    )}
                </footer>
            </div>
        </div>
    )
}
