import React, { useMemo, useState } from 'react'
import { ExperienceIcon, SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'
import '../../styles/screen.styles/experience.scss'
import { ExperienceCard } from './experience.card'
import { IOrganization } from './types'
export function Experience() {
    const experiences = useMemo<IOrganization[]>(() => {
        return CONTENT.experience.firms
    }, [])
    const [selectedIndex, setSelectedIndex] = useState(0)

    return (
        <section id="experience" className="experience-module">
            <div className="experience-wrapper">
                <SlideUpAnimation slideFrom={20}>
                    <title className="header">
                        <ExperienceIcon className="section-header-icon" />
                        {CONTENT.experience.title}
                    </title>
                </SlideUpAnimation>
                <SlideUpAnimation delay="0.5s" slideFrom={50}>
                    <div className="experience-content">
                        <div className="org-name-wrapper">
                            <div className="line exp-tab-line">
                                <div
                                    className={`line-marker marker-${selectedIndex}`}
                                ></div>
                            </div>
                            <div className="org-name-container">
                                {experiences.map((exp, i) => (
                                    <button
                                        key={`exp-org-item-${i}`}
                                        onClick={() => setSelectedIndex(i)}
                                        className={`org-name ${
                                            selectedIndex === i
                                                ? 'active-btn'
                                                : ''
                                        }`}
                                    >
                                        {exp.shortName}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <ExperienceCard {...experiences[selectedIndex]} />
                    </div>
                </SlideUpAnimation>
            </div>
        </section>
    )
}
