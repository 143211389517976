import React, { useMemo } from 'react'
import { SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'
interface Technology {
    name: string
    rating: number
}
export function AboutCard() {
    const technologies = useMemo<Technology[]>(() => {
        return CONTENT.about.frameworks
    }, [])
    return (
        <div className="about-card">
            <section className="content">
                {CONTENT.about.description.map((paragraph, i) => (
                    <SlideUpAnimation
                        // delay={`${(i + 1) * 0.3}s`}
                        slideFrom={50}
                        key={`about-desc-item-${i}`}
                    >
                        <p>{paragraph}</p>
                    </SlideUpAnimation>
                ))}
            </section>
            <section className="footer">
                <SlideUpAnimation
                    // delay={`${(CONTENT.about.description.length + 1) * 0.3}s`}
                    slideFrom={50}
                >
                    <ol className="technologies-list">
                        {technologies.map((technology, i) => (
                            <li
                                key={`about-tech-item-${i}`}
                                className="technology-item"
                            >
                                {technology.name}
                            </li>
                        ))}
                    </ol>
                </SlideUpAnimation>
            </section>
        </div>
    )
}
