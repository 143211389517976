import React, { useMemo, useState } from 'react'
import { IProject, ProjectCard, SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'

export function OtherProjects() {
    const [page, setPage] = useState(1)

    const projects = useMemo<IProject[]>(() => {
        const _projects: IProject[] = CONTENT.projects.other.slice(0, page * 6)
        return _projects
    }, [page])

    const haveMore = useMemo<boolean>(() => {
        return CONTENT.projects.other.length > page * 6
    }, [page])

    return (
        <>
            <SlideUpAnimation slideFrom={20}>
                <div className="other-projects-title">
                    {CONTENT.projects.noteworthyProjects}
                </div>
            </SlideUpAnimation>
            <ol className="other-projects">
                {projects.map((project, i) => (
                    <li key={`proj-other-item-${i}`}>
                        <SlideUpAnimation slideFrom={70} delay="0.3s">
                            <ProjectCard {...project} />
                        </SlideUpAnimation>
                    </li>
                ))}
            </ol>
            <SlideUpAnimation slideFrom={20}>
                <div
                    onClick={() => {
                        haveMore ? setPage(page + 1) : setPage(page - 1)
                    }}
                    className="projects-more-button app-button"
                >
                    <span className="hover-underline-animation">
                        {haveMore
                            ? CONTENT.projects.showMore
                            : CONTENT.projects.showLess}
                    </span>
                </div>
            </SlideUpAnimation>
        </>
    )
}
