import React from 'react'
import { SlideUpAnimation } from '../../components'
import { redirect } from '../../utils'
import { IOrganization } from './types'
export function ExperienceCard({
    longName: name,
    designation,
    url,
    from,
    to,
    experiences
}: IOrganization) {
    return (
        <div className="experience-card">
            <section className="title">
                <span className="designation">{designation} </span>
                <span
                    className="organization hover-underline-animation"
                    onClick={() => redirect(url)}
                >
                    @{name}
                </span>
            </section>
            <section className="sub-title">{`${from}-${to}`}</section>
            <section className="content">
                <ol>
                    {experiences.map((exp, i) => (
                        <SlideUpAnimation
                            // delay={`${(i + 1) * 0.3}s`}
                            slideFrom={50}
                            key={`exp-desc-item-${i}`}
                        >
                            <li className="content-item">{exp}</li>
                        </SlideUpAnimation>
                    ))}
                </ol>
            </section>
        </div>
    )
}
