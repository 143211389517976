import React from 'react'
import { IconBaseProps } from 'react-icons'
import {
    IoRocket,
    IoHome,
    IoBulb,
    IoCodeWorking,
    IoPerson
} from 'react-icons/io5'

export const ICONS = {
    home: '',
    about: '',
    experience: '',
    projects: '',
    contact: ''
}

export function HomeIcon(props: IconBaseProps) {
    return <IoHome {...props} />
}
export function AboutIcon(props: IconBaseProps) {
    return <IoRocket {...props} />
}
export function ExperienceIcon(props: IconBaseProps) {
    return <IoBulb {...props} />
}
export function ProjectsIcon(props: IconBaseProps) {
    return <IoCodeWorking {...props} />
}
export function ContactIcon(props: IconBaseProps) {
    return <IoPerson {...props} />
}
