import React, { useEffect, useState } from 'react'
import '../styles/component.styles/links.scss'
import { FaInstagram, FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'
import { redirect } from '../utils'
import { CONTENT } from '../config/content'
export function Links() {
    const [enableInstaLink, setInstaLink] = useState<boolean>(false)
    useEffect(() => {
        fetch(CONTENT.links.instagram)
            .then(() => setInstaLink(true))
            .catch((err) => setInstaLink(false))
    })
    return (
        <div className="links-wrapper">
            <FaLinkedin
                className="my-3 icon"
                onClick={() => redirect(CONTENT.links.linkedIn)}
            />
            <FaTwitter
                className="my-3 icon"
                onClick={() => redirect(CONTENT.links.twitter)}
            />
            <FaGithub
                className="my-3 icon"
                onClick={() => redirect(CONTENT.links.github)}
            />
            <FaInstagram
                className={`my-3 icon ${enableInstaLink ? '' : 'disabled'}`}
                onClick={() =>
                    enableInstaLink && redirect(CONTENT.links.instagram)
                }
            />
            <div className="h-4"></div>
        </div>
    )
}
