import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
    HomeIcon,
    AboutIcon,
    ExperienceIcon,
    ProjectsIcon,
    ContactIcon,
    Links
} from '.'
import '../styles/component.styles/navbar.scss'

export function Navbar() {
    const headerRef = useRef<HTMLElement | null>(null)
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [activeSection, setActiveSection] = useState(
        (window.location.href.includes('#') &&
            window.location.href.split('#').pop()) ||
            ''
    )
    useEffect(() => {
        let prevScrollpos = 0
        window.addEventListener('hashchange', function (e: any) {
            setActiveSection(e?.newURL?.split('#')?.pop() || '')
        })
        window.onscroll = () => {
            if (!headerRef.current) {
                return
            }
            const currentScrollPos = window.pageYOffset
            if (prevScrollpos > currentScrollPos) {
                if (currentScrollPos === 0) {
                    headerRef.current.style.cssText = ''
                } else {
                    headerRef.current.style.cssText = `top: 0px;
                        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                            0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
                        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
                            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; `
                }
            } else {
                headerRef.current.style.cssText = 'top: -200px;'
            }
            prevScrollpos = currentScrollPos
        }
    }, [])

    function onSwitchMenu() {
        // if (!isMenuOpen) {
        //     document
        //         .querySelector('.app-content-wrapper')
        //         ?.setAttribute('style', 'filter: blur(5px) brightness(0.7);')
        // } else {
        //     document
        //         .querySelector('.app-content-wrapper')
        //         ?.removeAttribute('style')
        // }
        setMenuOpen(!isMenuOpen)
    }

    function isActive(_section: string): boolean {
        if (_section === 'home' && activeSection === '') return true
        return _section === activeSection
    }

    function renderLink(
        label: string,
        sectionId: string,
        icon: ReactElement<any, any>
    ) {
        return (
            <li
                className={isActive(sectionId) ? 'heder-active-link' : ''}
                onClick={isMenuOpen ? onSwitchMenu : () => {}}
            >
                {icon}
                <a
                    className={'hover-underline-animation'}
                    href={`#${sectionId}`}
                >
                    {label}
                </a>
            </li>
        )
    }

    return (
        <>
            <header
                ref={(_ref) => (headerRef.current = _ref)}
                className="app-header"
            >
                <a href="https://www.lijovijayan.dev">
                    <img
                        className="app-logo"
                        src="/icons/apple-touch-icon.png"
                        alt=""
                    />
                </a>
                <div style={{ flex: 1 }}></div>
                <div
                    onClick={onSwitchMenu}
                    className={`hamberger-menu ${isMenuOpen ? 'open' : ''}`}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <aside
                    onClick={(e) => e.stopPropagation()}
                    className={`drawer-menu ${
                        isMenuOpen ? 'drawer-menu-open' : ''
                    }`}
                >
                    <ol className="drawer-list">
                        {renderLink(
                            'Home',
                            'home',
                            <HomeIcon className="menu-io-icon" />
                        )}
                        {renderLink(
                            'About',
                            'about',
                            <AboutIcon className="menu-io-icon" />
                        )}
                        {renderLink(
                            'Experience',
                            'experience',
                            <ExperienceIcon className="menu-io-icon" />
                        )}
                        {renderLink(
                            'Projects',
                            'projects',
                            <ProjectsIcon className="menu-io-icon" />
                        )}
                        {renderLink(
                            'Contact',
                            'contact',
                            <ContactIcon className="menu-io-icon" />
                        )}
                    </ol>
                    <Links />
                </aside>

                {isMenuOpen && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            onSwitchMenu()
                        }}
                        className="background-cover-layer"
                    ></div>
                )}
            </header>
        </>
    )
}
