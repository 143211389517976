import React from 'react'
import '../../styles/screen.styles/projects.scss'
import { FeaturedProjects } from './featured'
import { OtherProjects } from './other.projects'
export function Projects() {
    return (
        <section id="projects" className="projects-module">
            <FeaturedProjects />
            <OtherProjects />
        </section>
    )
}
