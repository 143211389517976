import { useState } from 'react'
import './styles/index.scss'
import './styles/app.scss'
import { Navbar, Email, Links, SplashScreen } from './components'
import { Contact, About, Experience, Home, Projects } from './screens'

function App() {
    const [isLoading, setLoading] = useState(true)
    function isContentLoaded() {
        setLoading(false)
    }
    // useEffect(() => {
    //     fetch('http://ip-api.com/json')
    //         .then((d) => d.json())
    //         .catch((err) => console.log(err))
    //         .then((d) => console.log(d))
    //         .catch((err) => console.log(err))
    // })
    return (
        <>
            {isLoading && <SplashScreen />}
            <div
                className={`app-container ${
                    isLoading ? 'content-loading' : ''
                }`}
            >
                <Navbar />
                <Email />
                <Links />
                <div className="app-content-wrapper">
                    <Home isContentLoaded={isContentLoaded} />
                    <About />
                    <Experience />
                    <Projects />
                    <Contact />
                </div>
            </div>
        </>
    )
}

export default App
