import React from 'react'
import { CONTENT } from '../config/content'
import '../styles/component.styles/email.scss'
export function Email() {
    return (
        <div className="email-wrapper">
            <a href={`mailto:${CONTENT.links.email}`} className="email-link">
                {CONTENT.links.email}
            </a>
        </div>
    )
}
