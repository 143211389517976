import React from 'react'
import { Links } from '.'
import { CONTENT } from '../config/content'
import '../styles/component.styles/footer.scss'
import { redirect } from '../utils'
export function Footer() {
    return (
        <footer className="app-footer">
            <Links />
            Developed by &nbsp;
            <span
                onClick={() => redirect(CONTENT.links.linkedIn)}
                className="app-footer-link hover-underline-animation"
            >
                Lijo Vijayan
            </span>
            &nbsp;| UX credits goes to&nbsp;
            <span
                onClick={() => redirect('https://www.linkedin.com/in/bchiang7')}
                className="app-footer-link hover-underline-animation"
            >
                Brittany Chiang
            </span>
        </footer>
    )
}
