import React from 'react'
import { AboutIcon, SlideUpAnimation } from '../../components'
import { CONTENT } from '../../config/content'
import '../../styles/screen.styles/about.scss'
import { AboutCard } from './about.card'

export function About() {
    return (
        <section id="about" className="about-module">
            <div className="about-wrapper">
                <SlideUpAnimation slideFrom={20}>
                    <section className="header">
                        <AboutIcon className="section-header-icon" />
                        {CONTENT.about.title}
                    </section>
                </SlideUpAnimation>
                <SlideUpAnimation delay="0.5s" slideFrom={50}>
                    <AboutCard />
                </SlideUpAnimation>
            </div>
        </section>
    )
}
