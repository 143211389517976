import React, { useState } from 'react'
import '../../styles/screen.styles/home.scss'
import { WelcomeMessage } from './welcome.message'
import Particles from 'react-tsparticles'
interface HomeProps {
    isContentLoaded: () => void
}
export function Home({ isContentLoaded }: HomeProps) {
    const [isParticlesLoaded, setPartilesLoaded] = useState(false)

    return (
        <section id="home" className="home-module">
            <ParticlesWrapper
                setPartilesLoaded={() => {
                    setTimeout(() => {
                        isContentLoaded()
                        setTimeout(() => {
                            setPartilesLoaded(true)
                        }, 800)
                    }, 3000)
                }}
            />
            {isParticlesLoaded && <WelcomeMessage />}
        </section>
    )
}
interface Props {
    setPartilesLoaded: () => void
}
const ParticlesWrapper = React.memo(ParticlesComponent, () => true)

function ParticlesComponent({ setPartilesLoaded }: Props) {
    const particlesInit = async (main: any) => {
        console.log(main)
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    }

    const particlesLoaded = async (container: any) => {
        setPartilesLoaded()
    }
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: 'transparant'
                    }
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: 'canvas',
                    events: {
                        onClick: {
                            enable: true,
                            mode: 'push'
                        },
                        onHover: {
                            enable: true,
                            mode: 'bubble'
                        },
                        resize: true
                    },
                    modes: {
                        bubble: {
                            color: '#64ffda',
                            distance: 200,
                            duration: 1,
                            opacity: 0.8,
                            size: 4
                        },
                        push: {
                            quantity: 1
                        }
                        // repulse: {
                        //     distance: 150,
                        //     duration: 0.4
                        // }
                    }
                },
                particles: {
                    color: {
                        value: '#8892b0'
                    },
                    links: {
                        color: '#8892b0',
                        distance: 150,
                        enable: true,
                        opacity: 0.1,
                        width: 1
                    },
                    collisions: {
                        enable: true
                    },
                    move: {
                        direction: 'none',
                        enable: true,
                        outMode: 'bounce',
                        random: false,
                        speed: 1,
                        straight: false
                    },
                    number: {
                        density: {
                            enable: true,
                            value_area: 800
                        },
                        value: 80
                    },
                    opacity: {
                        value: 0.1
                    },
                    shape: {
                        type: 'circle'
                    },
                    size: {
                        random: true,
                        value: 3
                    }
                },
                detectRetina: true
            }}
        />
    )
}
