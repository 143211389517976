import React from 'react'
import { FaGithub, FaPaperclip } from 'react-icons/fa'
import { IoOpenOutline } from 'react-icons/io5'
import { IProject } from '.'
import '../styles/component.styles/project.card.scss'
import { redirect } from '../utils'
interface Props extends IProject {}

export function ProjectCard({
    name,
    gitURL,
    description,
    demoURL,
    technologies
}: Props) {
    return (
        <div className="project-card-wrapper">
            <div className="project-card-header">
                <FaPaperclip className="project-main-icon" />
                {gitURL && (
                    <FaGithub
                        className="project-git-icon"
                        onClick={() => redirect(gitURL)}
                    />
                )}
                {demoURL && (
                    <IoOpenOutline
                        className="project-demo-icon"
                        onClick={() => redirect(demoURL)}
                    />
                )}
            </div>
            <div>
                <span className="project-card-title hover-underline-animation">
                    {name}
                </span>
                <div className="project-card-content">{description}</div>
            </div>
            <div className="project-card-footer">
                <ol className="project-card-tech">
                    {technologies.map((tech, i) => (
                        <li
                            key={`comp-proj-card-item-${i}`}
                            className="project-card-tech-item"
                        >
                            {tech}
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}
